@import 'variables';

@import 'app/themes/material/paint-theme.scss';

// Make all buttons uppercase
@mixin uppercase() {
	text-transform: uppercase;
}

$theme-colors: (
	'primary': $primary,
	'primary2': $primary2,
	'primary3': $primary3,
	'secondary': $secondary,
	'success': $success,
	'warning': $warning,
	'danger': $danger,
	'dark': $dark,
	'target': $target,
	'declined': $declined,
	'active': $active,
	'quoteReady': $quoteReady,
	'quoteApproved': $quoteApproved,
	'submitted': $submitted,
	'won': $won,
	'lost': $lost,
	'black': $black,
	'live': $live,
	'completed': $completed,
);

// Typography (font) Styles
$font-family-base: 'Inter', sans-serif;
$font-size-base: 0.8125rem; // 13px
$headings-font-weight: 600;

// Buttons
$input-btn-padding-x: 1.5rem;
$btn-border-radius: 28px;
$btn-font-weight: 600;
// Make all buttons have white text
$yiq-text-dark: #fff;

.btn {
	@include uppercase();

	&.btn-outline-primary,
	&.btn-outline-secondary,
	&.btn-outline-success,
	&.btn-outline-warning,
	&.btn-outline-danger,
	&.btn-outline-dark,
	&.btn-outline-target,
	&.btn-outline-declined,
	&.btn-outline-active,
	&.btn-outline-quoteReady,
	&.btn-outline-quoteApproved,
	&.btn-outline-submitted,
	&.btn-outline-won,
	&.btn-outline-lost,
	&.btn-outline-live,
	&.btn-outline-completed {
		background-color: $white;
	}
}

// boostrap 5 has a lot of warnings about colour contrast. Because the paint theme colours are so whack, we need
// to disable this warning until a real designer picks sane colours - Cal
$min-contrast-ratio: 2.5;

// This import must remain at the bottom
@import '../node_modules/bootstrap/scss/bootstrap';
