// Core theme colors
$primary0: #0EABF7;
$primary: #078BCB;
$primary2: #067ab2;
$primary3: #05699a;
$selectedBackground: #ebf5ff;

// Colour variables
$black: #000000;
$border-colour: #dee2e6;
$card-border: #dfdfdf;
$cardBorderGrey: #d9dee8;
$disabled: #caced5;
$disabledButtonColor: #7bb3d0;
$disabled-background: #ececec;
$form-error: #f84646;
$highlight: #f5f5f5;
$hoverHighlight: rgba(35, 31, 32, 0.31);
$inactive: rgba(255, 0, 0, 0.1);
$inactiveHighlight: rgba(255, 0, 0, 0.2);
$kaliber-dark: #414448;
$link: #2185d0;
$placeholder: #aab2c0;
$selectedButton: #002865;
$subtitle: #a0a2a4;
$white: #ffffff;
$cardBorderGrey: #d9dee8;

// Bootstrap Colours
$secondary: #222222;
$dark: #7a7a7a;
$background-light: #efefef;
$print-page-size: auto;

$danger: #d9534f;
$muted: #85959f;
$text-muted: #85959f;
$success: #61f2b6;
$warning: #f0ad4e;
$info: #5bc0de;
$light: #f8f9fa;
$light-gray: #d3d3d3;
$background-dark: #414448;
$disabled-background: rgba(221, 221, 221, 0.3) !default;

// Common Styles
$border: 1px solid $border-colour;
$appComsHeight: 60px;

/* WARNING: This section must be updated if _variables.ts is changed */
$target: #fcb800;
$declined: #d11f4b;
$active: #f77f21;
$quoteReady: #d299c4;
$quoteApproved: #7c4795;
$submitted: #662066;
$won: #3e9937;
$lost: #00245e;
$live: #00b0f0;
$completed: #a9d18e;

/* PERCENTAGE COLOURS */
$percentage-green: #15b422;
$percentage-light-green: #a3d000;
$percentage-yellow: #fcb000;
$percentage-red: #ff0000;

// DO NOT USE IF YOU SEE THESE BEING USED. PLEASE REMOVE
$exportTableHeight: 530px;
$footerHeight: 60px;
$height650px: 650px;
$headerNavHeight: 60px;
$headerHeight: 42px;
$inputPercentWidth: 91px;
$inputDiscountWidth: 126px;
$exportTableHeight: 530px;
$incExcTableHeight: 300px;
$sidebarMenuWidth: 120px;
$sideNavWidth: 67px;
$height650px: 650px;
$headerNavHeight: 60px;

// Image References
$loginLogo: './assets';
