@use '@angular/material' as mat;
@import 'variables';

@import '@angular/material/theming';

// Angular material Themes

// Generated from _variables.scss $primary with http://mcg.mbitson.com/
// color variation calculations can be found at https://razorltd.github.io/sasscolourfunctioncalculator/
$mat-primary: (
	50: lighten(desaturate(adjust-hue($primary2, -2), 15.85), 49.8),
	100: lighten(desaturate($primary2, 16.11), 39.61),
	200: lighten(desaturate($primary2, 15.97), 28.43),
	300: lighten(desaturate($primary2, 16.67), 17.06),
	400: lighten(desaturate($primary2, 16.48), 8.43),
	500: $primary2,
	600: darken(saturate(adjust-hue($primary2, 1), 2.38), 1.96),
	700: darken(saturate(adjust-hue($primary2, 3), 5.5), 4.71),
	800: darken(saturate(adjust-hue($primary2, 4), 8.24), 7.06),
	900: darken(saturate(adjust-hue($primary2, 8), 16.28), 11.57),
	A100: lighten(saturate(adjust-hue($primary2, 10), 29.86), 45.29),
	A200: lighten(saturate(adjust-hue($primary2, 10), 29.86), 35.29),
	A400: lighten(saturate(adjust-hue($primary2, 10), 29.86), 25.29),
	A700: lighten(saturate(adjust-hue($primary2, 11), 29.86), 20.39),
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);
// Generated from _variables.scss $secondary with http://mcg.mbitson.com/
$mat-accent: (
	50: #e8e9e9,
	100: #c6c7c8,
	200: #a0a2a4,
	300: #7a7c7f,
	400: #5e6063,
	500: #414448,
	600: #3b3e41,
	700: #323538,
	800: #2a2d30,
	900: #1c1f21,
	A100: lighten(saturate(adjust-hue($primary, 10), 29.86), 45.29),
	A200: lighten(saturate(adjust-hue($primary, 10), 29.86), 35.29),
	A400: lighten(saturate(adjust-hue($primary, 10), 29.86), 25.29),
	A700: lighten(saturate(adjust-hue($primary, 11), 29.86), 20.39),
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #ffffff,
		A700: #ffffff,
	),
);
// Angular material
@include mat.core();
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$primary-palette: mat.define-palette($mat-primary);
$accent-palette: mat.define-palette($mat-accent);

$custom-typography: mat.define-typography-config(
	$font-family: 'inherit',
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.

// Some fields are just the defaults, leaving them in to be able to customize easily in the future
mat.$light-theme-foreground-palette: (
	base: black,
	divider: $primary2,
	// Autocomplete bottom line
	dividers: $dark-dividers,
	disabled: $dark-disabled-text,
	disabled-button: rgba(black, 0.26),
	disabled-text: $dark-disabled-text,
	elevation: black,
	hint-text: $dark-disabled-text,
	secondary-text: rgba($primary2, 1),
	// Placeholder / label text for autocomplete
	icon: rgba(black, 0.54),
	icons: rgba(black, 0.54),
	text: black,
	// Text for dropdown / autocomplete items
	slider-min: rgba(black, 0.87),
	slider-off: rgba(black, 0.26),
	slider-off-active: rgba(black, 0.38)
);

$paint-theme: mat.define-light-theme(
	(
		color: (
			primary: $primary-palette,
			accent: $accent-palette,
			// can add a warn palette too
		),
	)
);

// Mat-datepicker style over rides.
.mat-form-field {
	width: 100%;

	:focus {
		outline: none;
	}

	.mat-form-field-flex {
		margin-top: -22px;
		font-size: 14px;
	}

	svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
		display: inline !important;
	}

	.mat-date-range-input-separator,
	.mat-date-range-input-inner::placeholder,
	.mat-input-element::placeholder {
		color: $placeholder;
	}
}

// Import mixin that applies custom styles to mat-form-field themes
@import './../mixins/inputs';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($paint-theme);
@include mat.all-component-typographies($custom-typography);
